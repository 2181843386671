<template>
  <section>
    <div
      v-if="mode == 'editor'"
      class="editor-toolbar"
      :class="isEditing ? 'editing' : ''"
    >
      <slot name="toolbar"></slot>
    </div>
    <EquipmentTablePanel
      v-bind:equipmentList="entryList"
      v-bind:equipmentCustomFields="equipmentCustomFields"
      v-bind:showName="true"
      v-bind:showLocation="true"
      v-bind:showAlarmStatus="true"
      v-bind:showConnectionStatus="true"
      v-bind:showSerialNumber="true"
      v-bind:showProcessArea="true"
      v-bind:showPagination="false"
      v-bind:ids="(equipment && [equipment.id]) || []"
      v-on:select="onSelect"
    >
    </EquipmentTablePanel>
    <div class="empty" v-if="!entryList || !entryList.length">
      {{ $t("no_equipment_was_found") }}
    </div>
  </section>
</template>

<script>
import EquipmentTablePanel from "@/components/equipment-table-panel.vue";
import { mapGetters } from "vuex";
export default {
  name: "EquipmentTableStationPanel",
  components: {
    EquipmentTablePanel
  },
  props: {
    equipment: {
      type: Object,
      default: null,
      required: false
    },
    display: {
      type: Object,
      required: true
    },
    panel: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      source: "device",
      fixedEquipmentProperties: [
        "nome",
        "numero_serie",
        "localizacao",
        "has_active_alarms",
        "is_connected",
        "process_area"
      ]
    };
  },
  computed: {
    entryList() {
      let lst = [];
      // updated device
      (this.deviceList || []).forEach((device) => {
        if (device.connector) {
          let deviceCopy = JSON.parse(JSON.stringify(device));
          // let item = deviceCopy.connector;
          // delete deviceCopy.connector;
          // item.device = deviceCopy;
          // lst.push(item);
          let connector = (this.equipmentList || []).find(
            ({ id }) => device.connector.id == id
          );
          if (!connector) {
            connector = device.connector;
          }
          connector = JSON.parse(JSON.stringify(connector));
          delete deviceCopy.connector;
          connector.device = deviceCopy;
          lst.push(connector);
        }
      });
      if (!lst.length) {
        // previous list already in memory
        for (var i in this.equipmentList) {
          let equipment = this.equipmentList[i];
          for (var j in equipment.devices || []) {
            let newEquipment = JSON.parse(JSON.stringify(equipment));
            newEquipment.device = equipment.devices[j];
            lst.push(newEquipment);
          }
        }
      }
      return lst;
    },
    allEquipmentFields() {
      let self = this;
      return (
        (self.$root.config.equipment_selection &&
          self.$root.config.equipment_selection.cols) ||
        []
      );
    },
    equipmentCustomFields() {
      let self = this;
      return this.allEquipmentFields.filter(function(i) {
        return self.fixedEquipmentProperties.indexOf(i.name) == -1;
      });
    },
    ...mapGetters("dashboard", {
      equipmentList: "connectorList",
      deviceList: "deviceListFromEquipment"
    })
  },
  watch: {
    equipment: {
      handler(n, o) {
        if (!o && n) {
          this.fetchDevices();
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    onSelect(query) {
      this.$store.commit(
        "SET_DEVICE_ID",
        query && query.device_id ? query.device_id : ""
      );
    },
    fetchDevices() {
      this.$store.dispatch("dashboard/fetchResourcesFrom", {
        resource: "device",
        connectorId: this.equipment?.id,
        forceUpdate: true
      });
    }
  },
  created() {
    // if (!this.deviceList?.length) this.fetchDevices();
    //this.fetchDevices();
  }
};
</script>

<style scoped>
.editor-toolbar {
  position: absolute;
  right: 0px;
  width: 100%;
  border-top: 3px solid #3c8dbc;
}

.editing {
  border-top-color: #f39c12;
}

.editor-toolbar > div {
  background-color: white;
}
div.empty {
  text-align: center;
  background-color: whitesmoke;
  margin-top: -20px;
  padding: 10px;
  border-radius: 0 0 5px 5px;
  border: 1px solid #ddd;
}
</style>
